/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { ReactElement } from "react";

import dynamic from "next/dynamic";

import Layout from "../../../../layouts/Layout";

const PageNotFound = dynamic(() => import("components/PageNotFound"));

export async function getServerSideProps(ctx: any) {
  const { getCommonServerSideProps } = await import(
    "helpers/getCommonServerSideProps"
  );
  const { commonProps } = await getCommonServerSideProps(ctx);
  return {
    props: {
      ...commonProps,
    },
  };
}

const PageNotFoundWrapper = (props: any) => {
  return <PageNotFound {...props} />;
};

PageNotFoundWrapper.getLayout = function getLayout(page: ReactElement) {
  const { props } = page;
  return <Layout config={props?.config}>{page}</Layout>;
};
export default PageNotFoundWrapper;
